import React from 'react'
import PropTypes from 'prop-types'

import './book.scss'

const Book = ({ copy, buttonText, buttonLink }) => (
  <div id="contact" className="book px-28 py-28 text-center">
    <div
      className="lg:max-w-5xl xl:max-w-6xl mx-auto ta-center mb-10"
      dangerouslySetInnerHTML={{
        __html: copy,
      }}
    />
    <a href={buttonLink} target="_blank">
      <button>{buttonText}</button>
    </a>
  </div>
)

Book.propTypes = {
  copy: PropTypes.string.isRequired,
}

export default Book
