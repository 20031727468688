import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import './portfolio.scss'

const PortfolioItemLightBox = ({ image, show, onClose }) => (
  <div
    className="lightbox"
    style={{ opacity: show ? 1 : 0, visibility: show ? 'visible' : 'hidden' }}
  >
    <div className="lightbox-x" onClick={onClose}></div>
    {image && image.src && (
      <Img
        fluid={image.src.childImageSharp.fluid}
        alt={image.alt}
        className="relative m-auto imageWrapper"
      />
    )}
  </div>
)

PortfolioItemLightBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  image:
    PropTypes.bool ||
    PropTypes.shape({
      src: PropTypes.object.isRequired,
      alt: PropTypes.string.isRequired,
    }),
}

export default PortfolioItemLightBox
