import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import './portfolio.scss'

const PortfolioItem = ({ copy, image, onThumbnailClick }) => (
  <div className="portfolio-item flex flex-col-reverse md:flex-row items-center lg:w-6/6 mx-auto mb-40 mt-40">
    <div className="w-full lg:w-1/4 text-gray text-center md:text-right md:pr-20 md:pl-20 copy">
      <p
        data-sal="slide-left"
        data-sal-easing="ease"
        data-sal-delay={250}
        data-sal-duration={500}
      >
        {copy}
      </p>
    </div>
    <div
      className="w-full lg:w-3/5 image py-16 px-10"
      onClick={onThumbnailClick}
    >
      <Img fluid={image.src.childImageSharp.fluid} alt={image.alt} />
    </div>
  </div>
)

PortfolioItem.propTypes = {
  copy: PropTypes.string.isRequired,
  onThumbnailClick: PropTypes.func.isRequired,
  image: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    src: PropTypes.object.isRequired,
  }),
}

export default PortfolioItem
