import React, { useEffect, useState } from 'react'
import { Parallax } from 'react-scroll-parallax'
import Logo from '../../images/other-fox-logo_animated.svg'

import './homeBanner.scss'

const HomeBanner = () => {
  const [disabledParalax, setDisabledParalax] = useState(false)

  useEffect(() => {
    var svgDoc = document
    var drawnFox = []
    var other = []
    var fox = []
    var devSol = []
    for (var i = 0; i <= 35; i++) {
      if (i <= 4 || (i >= 13 && i < 16)) {
        drawnFox.push(svgDoc.getElementsByClassName('kHywrXZV_' + i)[0])
      }
      if (i >= 5 && i < 10) {
        other.push(svgDoc.getElementsByClassName('kHywrXZV_' + i)[0])
      }
      if (i >= 10 && i < 13) {
        fox.push(svgDoc.getElementsByClassName('kHywrXZV_' + i)[0])
      }
      if (i >= 16) {
        devSol.push(svgDoc.getElementsByClassName('kHywrXZV_' + i)[0])
      }
    }
    setTimeout(function() {
      drawnFox.forEach(function(elem) {
        elem.style.fill = '#545454'
      })
    }, 2200)
    setTimeout(function() {
      other[0].style.fill = '#545454'
    }, 3000)
    setTimeout(function() {
      other[1].style.fill = '#545454'
    }, 3100)
    setTimeout(function() {
      other[2].style.fill = '#545454'
    }, 3200)
    setTimeout(function() {
      other[3].style.fill = '#545454'
    }, 3300)
    setTimeout(function() {
      other[4].style.fill = '#545454'
    }, 3400)
    setTimeout(function() {
      fox[0].style.fill = '#ffffff'
    }, 3200)
    setTimeout(function() {
      fox[1].style.fill = '#ffffff'
    }, 3300)
    setTimeout(function() {
      fox[2].style.fill = '#ffffff'
      devSol.forEach(function(elem) {
        elem.style.fill = '#ffffff'
      })
    }, 3400)
    setTimeout(function() {
      other.forEach(function(elem) {
        elem.style.transition = 'none'
        elem.style.fill = '#ffffff'
      })
      fox.forEach(function(elem) {
        elem.style.transition = 'none'
        elem.style.fill = '#545454'
      })
    }, 4200)

    if (window.outerWidth < 768) {
      setDisabledParalax(true)
    }
  }, [])

  return (
    <header id="header" className="flex flex-col justify-center items-center">
      <Parallax y={[-50, 50]} tagOuter="div" disabled={disabledParalax}>
        <div className="block logo">
          <Logo />
        </div>
      </Parallax>
    </header>
  )
}

export default HomeBanner
