import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from 'components/layout'
import HomeBanner from 'components/homeBanner'
import Nav from 'components/nav'
import About from 'components/about'
import Portfolio from 'components/portfolio'
// import FormContact from 'components/form-contact'
import Tech from 'components/tech'
import Book from 'components/book'
// import InstagramFeed from 'components/instagram-feed'
import Footer from 'components/footer'

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <HomeBanner />
      <Nav />
      <main className="main">
        <About content={data.homeJson.about.childMarkdownRemark.html} />
        <Tech />
        <section className="gradientBackground">
          <Portfolio items={data.homeJson.portfolio} />
          <Book
            {...data.homeJson.book}
            copy={data.homeJson.book.copy.childMarkdownRemark.html}
          />
          {/* <InstagramFeed /> */}
        </section>
      </main>
      <Footer />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    homeJson {
      title
      heading
      about {
        childMarkdownRemark {
          html
        }
      }
      portfolio {
        copy
        image {
          alt
          src {
            childImageSharp {
              fluid(maxHeight: 600, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
                src
              }
            }
          }
        }
      }
      book {
        copy {
          childMarkdownRemark {
            html
          }
        }
        buttonText
        buttonLink
      }
    }
  }
`
