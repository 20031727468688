import React from 'react'

import './tech.scss'

const BootstrapIcon = '/tech/bootstrap.svg'
const CSSIcon = '/tech/css3.svg'
const DockerIcon = '/tech/docker.svg'
const GatsbyIcon = '/tech/gatsbyjs.svg'
const GraphQLIcon = '/tech/graphql.svg'
const HTML5Icon = '/tech/html5.svg'
const JavascriptIcon = '/tech/javascript.svg'
const JesttIcon = '/tech/jest.svg'
const KnexIcon = '/tech/knexjs.svg'
const LessIcon = '/tech/less.svg'
const MaterialUIIcon = '/tech/material-ui.svg'
const MYSQLIcon = '/tech/mysql.svg'
const NextIcon = '/tech/nextjs.svg'
const NodeJsIcon = '/tech/nodejs.svg'
const ReactIcon = '/tech/reactIcon.svg'
const SassIcon = '/tech/sass.svg'
const StylusIcon = '/tech/stylus.svg'
const PostgreSQLIcon = '/tech/postgresql.svg'
const WordpressIcon = '/tech/wordpress.svg'

const Tech = () => (
  <div id="tech" className="py-28 px-14">
    <div className="tech bg-white">
      <img src={BootstrapIcon} className="tech-icon" />
      <img src={DockerIcon} className="tech-icon" />
      <img src={GatsbyIcon} className="tech-icon" />
      <img src={GraphQLIcon} className="tech-icon" />
    </div>
    <div className="tech bg-white">
      <img src={HTML5Icon} className="tech-icon" />
      <img src={JavascriptIcon} className="tech-icon" />
      <img src={JesttIcon} className="tech-icon" />
      <img src={KnexIcon} className="tech-icon" />
      <img src={LessIcon} className="tech-icon" />
      <img src={MaterialUIIcon} className="tech-icon" />
    </div>
    <div className="tech bg-white">
      <img src={MYSQLIcon} className="tech-icon" />
      <img src={WordpressIcon} className="tech-icon" />
      <img src={NextIcon} className="tech-icon" />
      <img src={NodeJsIcon} className="tech-icon" />
      <img src={ReactIcon} className="tech-icon" />
    </div>
    <div className="tech bg-white">
      <img src={SassIcon} className="tech-icon" />
      <img src={StylusIcon} className="tech-icon" />
      <img src={CSSIcon} className="tech-icon" />
      <img src={PostgreSQLIcon} className="tech-icon" />
    </div>
  </div>
)

export default Tech
