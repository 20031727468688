import React, { useEffect, useState } from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from 'components/scroll'

import './nav.scss'

const Nav = () => {
  const [style, setStyle] = useState({})

  const handleScroll = e => {
    const footer = document.getElementsByTagName('footer')[0].offsetHeight
    const body = document.body.offsetHeight
    const position = window.pageYOffset + window.innerHeight
    if (body - footer <= position) {
      setStyle({ position: 'absolute', bottom: `calc(${footer}px + 13vh)` })
    } else {
      setStyle({})
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [setStyle])

  return (
    <nav className="fixed" style={style}>
      <Scrollspy
        items={['header', 'about', 'tech', 'portfolio', 'contact']}
        className="text-right"
        currentClassName="active"
        offset={-500}
      >
        <li className="hidden">
          <Scroll type="id" element="header">
            <a href="#" className="uppercase py-4">
              Home
            </a>
          </Scroll>
        </li>
        <li>
          <Scroll type="id" element="about">
            <a href="#" className="uppercase py-4">
              Who?
            </a>
          </Scroll>
        </li>
        <li>
          <Scroll type="id" element="tech">
            <a href="#" className="uppercase py-4">
              Technology
            </a>
          </Scroll>
        </li>
        <li>
          <Scroll type="id" element="portfolio">
            <a href="#" className="uppercase py-4">
              Case Studies
            </a>
          </Scroll>
        </li>
        <li>
          <Scroll type="id" element="contact">
            <a href="#" className="uppercase py-4">
              Contact
            </a>
          </Scroll>
        </li>
      </Scrollspy>
    </nav>
  )
}

export default Nav
