import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PortfolioItem from './portfolio-item'
import PortfolioItemLightBox from './portfolio-lightbox'

import './portfolio.scss'

const Portfolio = ({ items }) => {
  const [lightbox, setLightbox] = useState({
    open: false,
    image: false,
  })

  const onOpen = image => setLightbox({ open: true, image })
  const onClose = () =>
    setLightbox({
      open: false,
      image: false,
    })

  return (
    <div id="portfolio" className="portfolio px-24">
      <div className="container mx-auto lg:pb-20">
        {items.map((item, i) => (
          <div
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-delay={0}
            data-sal-duration={500}
            className="portfolio-item-cont"
            key={i}
          >
            <PortfolioItem
              copy={item.copy}
              image={item.image}
              index={i}
              onThumbnailClick={() => onOpen(item.image)}
            />
          </div>
        ))}
      </div>
      <PortfolioItemLightBox
        image={lightbox.image}
        show={lightbox.open}
        onClose={onClose}
      />
    </div>
  )
}

Portfolio.propTypes = {
  items: PropTypes.array.isRequired,
}

export default Portfolio
